import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import {
  getReferralCodeFromStorage,
  saveReferralCodeToStorage,
} from '../functions'

import { removeQueryParam } from 'utils/removeQueryParam'

const useReferralCode = () => {
  const [code, setCode] = useState<string | null>()

  const router = useRouter()
  const referralCode = router.query?.referral_code

  useEffect(() => {
    if (referralCode) {
      saveReferralCodeToStorage(`${referralCode}`)
      setCode(`${referralCode}`)
      removeQueryParam('referral_code', router)
    } else {
      setCode(getReferralCodeFromStorage())
    }
  }, [referralCode])

  return code
}

export default useReferralCode
