import { SimpleGrid } from '@chakra-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getAboutMenuItems } from '../LeftNav/menuItems'
import NavCard from './NavCard'

const AboutNavContent = () => {
  const { t } = useTranslation()

  return (
    <SimpleGrid spacing={2} m="auto" maxW="1440px" width="100%" columns={4}>
      {getAboutMenuItems(t).map((link) => {
        return <NavCard key={link.href} {...link} />
      })}
    </SimpleGrid>
  )
}

export default AboutNavContent
