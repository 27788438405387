import { useFeatureFlag } from "../../optimizely/hooks/useFeatureFlag";

/**
 * This hook will load the defined feature flag for showing the low stock badge
 * for a certain product
 */
export function useIdsToShowLowStockBadge(): [number] {
    return useFeatureFlag(
        'SHOW_LOW_STOCK_BADGE',
        []
    ) || []
}