import { Text, Stack, Button } from '@chakra-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SuccessMark } from '../../../shared/components/SuccessMark'

interface EmailSubscriptionSuccessProps {
  onButtonClick: () => void
}

const EmailSubscriptionSuccess: FC<EmailSubscriptionSuccessProps> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={4} textAlign="center">
      <SuccessMark />
      <Text size="md" mb={10}>
        {t(
          'account:our-email-list-thank-you-modal',
          "Thank you, you've been added to our email list!"
        )}
      </Text>
      <Button width="100%" mt={12} onClick={onButtonClick}>
        {t('common:ok', 'OK')}
      </Button>
    </Stack>
  )
}

export default EmailSubscriptionSuccess
