import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M32 6.076c-1.177 0.523-2.443 0.875-3.771 1.033 1.356-0.812 2.397-2.099 2.887-3.632-1.268 0.752-2.673 1.299-4.169 1.593-1.196-1.276-2.904-2.073-4.792-2.073-4.239 0-7.353 3.955-6.396 8.060-5.455-0.273-10.292-2.887-13.531-6.859-1.72 2.951-0.892 6.811 2.031 8.765-1.075-0.035-2.088-0.329-2.972-0.821-0.072 3.041 2.108 5.887 5.265 6.52-0.924 0.251-1.936 0.309-2.965 0.112 0.835 2.608 3.259 4.505 6.133 4.559-2.76 2.164-6.237 3.131-9.72 2.72 2.905 1.863 6.357 2.949 10.064 2.949 12.189 0 19.076-10.295 18.66-19.528 1.283-0.927 2.396-2.083 3.276-3.399z"
    />
  ),
})
