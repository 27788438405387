import { HStack, Text, Stack } from '@chakra-ui/core'
import { css } from '@emotion/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getproductConcerns } from '../../../products/constants/productConcerns'
import { getProductsPageDetails } from '../../../products/constants/productsPageDetails'
import productRoutes from '../../../products/routes'
import ShopifyNextLink from '../../../shared/components/ShopifyNextLink'
import FeaturedProductsSection from './FeaturedProductsSection'

type ShopNavContentProps = {
  onClose: () => void
}

const ShopNavContent: FC<ShopNavContentProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const productsCategories = getProductsPageDetails(t)
  const productConcerns = getproductConcerns(t)
  const [allSkinConcern, ...otherProductConcerns] = productConcerns
  const concerns = [...otherProductConcerns]

  const sortedProductConcerns = [
    allSkinConcern,
    ...concerns.sort((a, b) => (a.title < b.title ? -1 : 1)),
  ]

  return (
    <HStack spacing={[8, null, 16]} px={2} align="flex-start">
      <HStack spacing={8} justify="space-around" align="flex-start" width="50%">
        <Stack spacing={4}>
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            color="moss"
            fontSize="0.813rem"
          >
            {t('common:by-category', 'By Category')}
          </Text>
          <Stack>
            {productsCategories.map((productCategory) => (
              <ShopifyNextLink
                href={productRoutes.products({
                  category: productCategory.value,
                })}
                fontSize="0.875rem"
                mb={2}
                onClick={onClose}
              >
                {productCategory.title}
              </ShopifyNextLink>
            ))}
          </Stack>
        </Stack>
        <Stack spacing={4}>
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            color="moss"
            fontSize="0.813rem"
          >
            {t('common:by-concern', 'By Concern')}
          </Text>
          <Stack
            maxH="350px"
            width="150px"
            overflowY="scroll"
            css={css`
              ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
              }
              ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: lightGray;
                box-shadow: 0 0 1px lightGray;
              }
            `}
          >
            {sortedProductConcerns.map((productConcern) => (
              <ShopifyNextLink
                href={productRoutes.productsWithQuery({
                  concerns: productConcern.value,
                })}
                fontSize="0.875rem"
                lineHeight="24px"
                mb={3}
                onClick={onClose}
              >
                {productConcern.title}
              </ShopifyNextLink>
            ))}
          </Stack>
        </Stack>
      </HStack>
      <FeaturedProductsSection onClose={onClose} />
    </HStack>
  )
}

export default ShopNavContent
