import { TFunction } from 'i18next'


import howToRoutes from '../../../howTo/routes'
import ingredientsRoutes from '../../../ingredients/routes'
import productRoutes from '../../../products/routes'
import staticRoutes from '../../../static/routes'

import { returnPolicyPage } from 'modules/shared/constants/general'

export interface NavLink {
  title: string
  href: string
  internal?: boolean
  className?: string
  isHidden?: boolean
}

export const getShopLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:skin', 'Skin'),
    href: productRoutes.products({ category: 'face' }),
    internal: true,
  },
  {
    title: t('footer:hair', 'Hair'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:body', 'Body'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
]

export const getAboutUsLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:our-story', 'Our Story'),
    href: productRoutes.products({ category: 'face' }),
    internal: true,
  },
  {
    title: t('footer:careers', 'Careers'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:our-stores', 'Our stores'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
]

export const getSocialMediaLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:instagram', 'Instagram'),
    href: productRoutes.products({ category: 'face' }),
    internal: true,
  },
  {
    title: t('footer:twitter', 'Twitter'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:linkedin', 'LinkedIn'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:facebook', 'Facebook'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
]

export const getOrdersAndSupportLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:contact-us', 'Contact us'),
    href: productRoutes.products({ category: 'face' }),
    internal: true,
  },
  {
    title: t('footer:faqs', 'FAQs'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:delivery-and-returns', 'Delivery & returns'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:track-your-order', 'Track your order'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
  {
    title: t('footer:terms-and-conditions', 'Terms and conditions'),
    href: productRoutes.products({ category: 'hair-and-body' }),
    internal: true,
  },
]

export const getDiscoverLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:meridian', 'Meridian'),
    href: productRoutes.products({ category: 'face' }),
    internal: true,
  },
]

export const getAboutLinks = (t: TFunction): NavLink[] => [
  {
    title: t('footer:find-us-in-store', 'Find Us In Store'),
    href: staticRoutes.findUs(),
    internal: true,
  },
  {
    title: t('footer:blog', 'Blog'),
    href: '/blog',
    internal: true,
  },
  {
    title: t('common:how-to', 'How To'),
    href: howToRoutes.howTo(),
    internal: true,
  },
  {
    title: t('footer:ingredients', 'Ingredients'),
    href: ingredientsRoutes.ingredients(),
    internal: true,
  },
  {
    title: t('footer:reviews', 'Reviews'),
    href: '/pages/customer-reviews',
    internal: false,
  },
  {
    title: t('common:accessibility', 'Accessibility'),
    href: staticRoutes.accessibility(),
    internal: true,
  },
  {
    title: t('nav:support', 'Support'),
    internal: true,
    href: 'https://luminskin.zendesk.com/hc/en-us',
  },
]

export const getMoreLinks = (
  t: TFunction,
  freeTrialDisabled: boolean
): NavLink[] => [
  {
    title: t('footer:track-my-order', 'Track My Order'),
    href: 'https://orderlookup.pmall.shop/lumin',
    internal: false,
  },
  {
    title: t('leftNav:return-refund-policy', 'Return & Refund Policy'),
    href: returnPolicyPage,
    internal: false,
  },
  {
    title: t('footer:jobs', 'Jobs'),
    href: 'https://angel.co/company/skinco',
    internal: false,
  },
  {
    title: t('footer:wholesale', 'Wholesale'),
    href: 'mailto:wholesale@luminskin.com',
    internal: false,
  },
  {
    title: t('footer:heroes-program', 'Heroes Program'),
    href: '/pages/heroes-program-discount/',
    internal: true,
  },
  {
    title: t('leftNav:lumin-influencer', 'Lumin Influencer'),
    href: staticRoutes.affiliateInfluencer(),
    internal: true,
  },
  {
    title: t('footer:request-personal-data', 'Request Personal Data'),
    href: 'https://luminskin.zendesk.com/hc/en-us/requests/new',
    internal: false,
  },
]
