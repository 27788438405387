import { Box, BoxProps } from '@chakra-ui/core'
import React from 'react'

type NavItemProps = BoxProps & {
  display?: string[]
  onClick?: () => void
  href?: string
  mr?: number
}

const NavItem: React.FC<NavItemProps> = (props) => {
  return (
    <Box
      as="a"
      fontSize="14px"
      lineHeight="17px"
      mr={5}
      py={1}
      px={4}
      transition="color .3s ease-in-out"
      _hover={{
        // TODO
        color: '#3d5b57',
      }}
      {...props}
    />
  )
}

export default NavItem
