import React from 'react'
import { useTranslation } from 'react-i18next'

import ShopifyNextLink from './ShopifyNextLink'

interface ReturnPolicyLinkProps {
  mr?: number
  ml?: number
  text?: string
}

const ReturnPolicyLink: React.FC<ReturnPolicyLinkProps> = ({
  text,
  ...other
}) => {
  const { t } = useTranslation()

  return (
    <ShopifyNextLink
      href={'/pages/refund-policy'}
      textDecoration="underline"
      {...other}
    >
      {text || t('footer:return-policy', 'Return Policy')}
    </ShopifyNextLink>
  )
}

export default ReturnPolicyLink
