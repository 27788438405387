import { BoxProps, Box, Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useIsFreeTrialDisabled } from '../../../freeTrial/hooks/useIsFreeTrialDisabled'
import { useRTL } from '../../../i18n/hooks'
import PrivacyPolicyLink from '../../../shared/components/PrivacyPolicyLink'
import ReturnPolicyLink from '../../../shared/components/ReturnPolicyLink'
import ShopifyNextLink from '../../../shared/components/ShopifyNextLink'
import SocialMediaLinks from '../../../shared/components/SocialMediaLinks'
import staticRoutes from '../../../static/routes'
import FooterCards from './FooterCards'
import { getShopLinks, getAboutLinks, getMoreLinks } from './footerConstants'
import ListOfLinks from './ListOfLinks'
import StayInTouchBlock from './StayInTouchBlock'

export type FooterProps = BoxProps

const copyRightYear = new Date().getFullYear()

const Footer: React.FC<FooterProps> = ({ ...boxProps }) => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()
  const freeTrialDisabled = useIsFreeTrialDisabled()

  return (
    <Box
      width="100%"
      color="offwhite"
      py={[8, 16, 16]}
      px={[5, 12, 12]}
      {...boxProps}
    >
      <Flex direction="column">
        <Flex direction={['column', 'row', 'row']}>
          <StayInTouchBlock width={[1, 1 / 2, 2 / 5]} />
          <Flex
            direction="row"
            justify="space-around"
            align="flex-start"
            width={[1, 1 / 2, 3 / 5]}
            wrap="wrap"
          >
            <Box width={[1 / 2, 1 / 2, 1 / 5]} mb={4}>
              <ListOfLinks
                listTitle={t('footer:shop', 'Shop')}
                links={getShopLinks(t)}
              />
            </Box>
            <Box width={[1 / 2, 1 / 2, 11 / 50]} mb={4}>
              <ListOfLinks
                listTitle={t('footer:about', 'About')}
                links={getAboutLinks(t)}
              />
            </Box>

            <Box width={[1 / 2, 1 / 2, 14 / 50]} mb={4}>
              <ListOfLinks
                listTitle={t('footer:more', 'More')}
                links={getMoreLinks(t, freeTrialDisabled === true)}
              />
            </Box>
            <SocialMediaLinks width={[1 / 2, 1 / 2, 3 / 10]} size="sm" />
          </Flex>
        </Flex>
        <Flex direction="column">
          <Text fontSize={['sm', 'md', 'md']} fontWeight="bold" mb={2}>
            {t('footer:need-help', 'Need help?')}
          </Text>
          <Text size="md">
            {t('footer:check-out-our', 'Check out our')}&nbsp;
            <ShopifyNextLink
              href="https://luminskin.zendesk.com/hc/en-us"
              textDecoration="underline"
              mr={isRTL ? 1 : 0}
            >
              {t('footer:support-page', 'support page')}
            </ShopifyNextLink>
          </Text>
        </Flex>
        <Box display={['block', 'none']} height="1px" bg="offwhite" mt={2} />
        <Flex
          pt={[4, 6, 10]}
          mb={[4, 6, 10]}
          justify={['flex-end', 'flex-start', 'flex-start']}
          align="center"
          flexDirection={['row-reverse', 'row']}
        >
          <Box mr={isRTL ? 0 : [0, 2, 2]} ml={isRTL ? [0, 2, 2] : 0}>
            © {copyRightYear},&nbsp;
            <ShopifyNextLink
              label="home page"
              _hover={{ textDecoration: 'underline' }}
              href="/"
            >
              Lumin
            </ShopifyNextLink>
          </Box>
          <FooterCards />
        </Flex>
        <Flex justify={['flex-end', 'flex-start']}>
          <PrivacyPolicyLink mr={isRTL ? 0 : 2} ml={isRTL ? 2 : 0} />
          <ReturnPolicyLink mr={isRTL ? 0 : 2} ml={isRTL ? 2 : 0} />
          <ShopifyNextLink
            href={staticRoutes.termsOfService()}
            textDecoration="underline"
          >
            {t('footer:terms-of-service', 'Terms of Service')}
          </ShopifyNextLink>
        </Flex>
        <Box mt={4}>
          <Text as="span">716 Monterey Pass Rd Monterey Park 91754</Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Footer
