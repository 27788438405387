import {
  getCookie,
  getUserCountryCode,
} from '@pangaea-holdings/pangaea-checkout'
import { useState, useEffect } from 'react'

import envConfig from '../../../core/config'
import { FREE_TRIAL_COUNTRY_WHITELIST } from '../constants/freeTrialWhitelist'

const ENABLE_FREE_TRIAL = 'enableFreeTrial'

export function useIsFreeTrialDisabled() {
  const [trialDisabled, setTrialDisabled] = useState<boolean | null>(null)

  useEffect(() => {
    const isFreeTrialEnabledForDev =
      getCookie(ENABLE_FREE_TRIAL) === 'true' &&
      envConfig('ENVIRONMENT') !== 'production'

    if (isFreeTrialEnabledForDev) {
      setTrialDisabled(false)
    } else {
      getUserCountryCode()
        .then((countryCode) => {
          if (countryCode) {
            const disabled = !FREE_TRIAL_COUNTRY_WHITELIST.includes(countryCode)
            setTrialDisabled(disabled)
          }
        })
        .catch(() => setTrialDisabled(false))
    }
  }, [])

  return trialDisabled
}

export default useIsFreeTrialDisabled
