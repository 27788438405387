import { Box, Button, Flex } from '@chakra-ui/core'
import {
  CartResponse,
  CheckoutContext,
  removeCartOffer,
  removeCookie,
  setTrackingFunctions,
} from '@pangaea-holdings/pangaea-checkout'
import dynamic from 'next/dynamic'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Drawer } from '../../../../design/components'
import * as track from '../../../../modules/analytics/functions/track'
import { useLanguage } from '../../../i18n/hooks'
import { useVatConfig } from '../../../vat/hooks'
import MakeItASubscriptionModal from '../MakeItASubscriptionModal'

import { isClearanceCartItem } from 'modules/cartv2/functions'
import { hiddenCustomizationOptions } from 'modules/checkout/constants/products'
import { isValidDiscountCode } from 'modules/checkout/validDiscountCodes'
import { CountryAndLanguageSelector } from 'modules/lumin/components/CountryAndLanguageSelector'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { useIdsToShowLowStockBadge } from 'modules/products/hooks/useIdsToShowLowStockBadge'
import { toggleZendeskChatButtonDisplay } from 'modules/shared/hooks/useZendeskChat'
import { PROMOCODE_COOKIE } from 'utils/constants'

export interface CartDrawerProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
}

const CartContents = dynamic(
  () =>
    import('@pangaea-holdings/pangaea-checkout').then(
      (mod) => mod.CartContents
    ),
  { ssr: false }
)

const useRemoveFreeTrialOfferInCart = () => {
  const { state, dispatch } = useContext(CheckoutContext)
  const { enabled: isVatCalcEnabled } = useVatConfig()

  useEffect(() => {
    const qualifiedOffers = state.cart.cart?.qualifiedOffers
    if (!qualifiedOffers?.length) {
      return
    }

    const ftOffer = qualifiedOffers.find((offer) => offer.type === 'FREE_TRIAL')

    if (ftOffer) {
      dispatch(removeCartOffer(ftOffer.slug, isVatCalcEnabled))
    }
  }, [state.cart])
}

export const CartDrawer: FC<CartDrawerProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()

  const [showConvertToSubModal, setShowConvertToSubModal] =
    React.useState(false)

  const enableAvailablePaymentMethods = useFeatureFlag(
    'ENABLE_CART_PAYMENT_METHODS',
    false
  )

  useRemoveFreeTrialOfferInCart()

  const { language } = useLanguage()

  const [PreCheckoutModal, setPreCheckoutModal] = useState<React.FC<{
    isOpen: boolean
    setIsOpen: (val: boolean) => void
  }> | null>(null)

  const vatConfig = useVatConfig()
  const idsToShowLowStockBadge = useIdsToShowLowStockBadge()

  useEffect(() => {
    toggleZendeskChatButtonDisplay(isOpen ? 'hide' : 'show')
  }, [isOpen])

  useEffect(() => {
    setTrackingFunctions(track)
  }, [])

  const handleDiscountRemoved = () => {
    removeCookie(PROMOCODE_COOKIE)
  }

  const otpDescription = ' '

  const subscriptionDescription = t(
    'checkout:subscription-purchase',
    'Subscription Purchase'
  )

  const handleBeforeProceedToCheckout = (
    cart: CartResponse,
    proceedToCheckout
  ) => {
    const hasNonClearanceOTPItemInCart = cart?.items.some((item) =>
      item.subscription ? false : !isClearanceCartItem(item)
    )

    if (hasNonClearanceOTPItemInCart) {
      setPreCheckoutModal(
        () =>
          function PreModal({ isOpen, setIsOpen }) {
            return (
              <MakeItASubscriptionModal
                cart={cart}
                proceedToCheckout={proceedToCheckout}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            )
          }
      )
      setShowConvertToSubModal(true)
      return
    }

    proceedToCheckout()
  }

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeButton={false}
    >
      <Flex position="relative" w="100%" direction="column">
        <Box
          display={['block', 'none']}
          position="absolute"
          right="5"
          top={['35px', '40px']}
        >
          <CountryAndLanguageSelector />
        </Box>
        <CartContents
          setIsOpen={setIsOpen}
          bgColor="rust"
          color="cartTextColor"
          mx={['0.4rem', '0.875rem']}
          my={['0.25rem', '0.5625rem']}
          otpDescription={otpDescription}
          subscriptionDescription={subscriptionDescription}
          hiddenCustomizationOptions={hiddenCustomizationOptions}
          enablePromotions
          discountFilterFn={isValidDiscountCode}
          onDiscountRemoved={handleDiscountRemoved}
          enableAvailablePaymentMethods={enableAvailablePaymentMethods}
          vatConfig={vatConfig}
          idsToShowLowStockBadge={idsToShowLowStockBadge}
          onBeforeProceedToCheckout={handleBeforeProceedToCheckout}
          enableCurrencySelector={false}
          language={language}
        />
        {PreCheckoutModal && (
          <PreCheckoutModal
            isOpen={showConvertToSubModal}
            setIsOpen={setShowConvertToSubModal}
          />
        )}
      </Flex>
    </Drawer>
  )
}
