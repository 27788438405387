import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const CancelIcon = createIcon({
  displayName: 'CancelIcon',
  viewBox: '0 0 18 18',
  path: (
    <path
      d="M8.79486 8.99261L0.672852 17.1146L8.79486 8.99261L0.672852 0.870605L8.79486 8.99261L16.9169 0.870605L8.79486 8.99261L16.9169 17.1146L8.79486 8.99261Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
