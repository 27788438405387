import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const ShoppingCartIcon = createIcon({
  displayName: 'ShoppingCartIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M7.5 21.75C8.32843 21.75 9 21.0784 9 20.25C9 19.4216 8.32843 18.75 7.5 18.75C6.67157 18.75 6 19.4216 6 20.25C6 21.0784 6.67157 21.75 7.5 21.75Z"
        fill="#FCFCF9"
      />
      <path
        d="M17.25 21.75C18.0784 21.75 18.75 21.0784 18.75 20.25C18.75 19.4216 18.0784 18.75 17.25 18.75C16.4216 18.75 15.75 19.4216 15.75 20.25C15.75 21.0784 16.4216 21.75 17.25 21.75Z"
        fill="#FCFCF9"
      />
      <path
        d="M3.96429 6.75H20.7857L18.3108 15.4121C18.2213 15.7255 18.0321 16.0012 17.7718 16.1975C17.5116 16.3938 17.1945 16.5 16.8685 16.5H7.88145C7.55548 16.5 7.23839 16.3938 6.97816 16.1975C6.71792 16.0012 6.52871 15.7255 6.43916 15.4121L3.04827 3.54396C3.0035 3.38725 2.90889 3.24939 2.77878 3.15124C2.64866 3.05309 2.49011 3 2.32713 3H0.75"
        stroke="#FCFCF9"
        strokeWidth="1.49508"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})
