import buildRoutes, {
  routeFromUrl,
  stringifyQuery,
} from '../shared/buildRoutes'

const productRoutes = buildRoutes('products', {
  products: ({
    category,
  }: { category?: string } = {}) => {
    if (!category || category === 'all') {
      return ''
    }
    return `?category=${category}`
  },
  productSearch: ({
    q,
  }: { q?: string } = {}) => {
    if (!q) {
      return ''
    }
    return `?q=${q}`
  },
  productsWithQuery: (query: { [key: string]: any } = {}) => {
    const queryString = stringifyQuery(query)
    return queryString ? `?${queryString}` : ''
  },
  detail: routeFromUrl<{ slug: string }>('[slug]'),
})
export default productRoutes
