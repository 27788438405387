import { Flex, Text, Stack, Box } from '@chakra-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useProducts } from '../../../products/hooks'
import productRoutes from '../../../products/routes'
import { ArrowForward } from '../../../shared/components/Icons/ArrowForward'
import ShopifyNextLink from '../../../shared/components/ShopifyNextLink'

type FeaturedProductsSectionProps = {
  onClose: () => void
}

const FeaturedProductsSection: FC<FeaturedProductsSectionProps> = ({
  onClose,
}) => {
  const { t } = useTranslation()
  const { products } = useProducts({ productIds: [59, 13] })

  return (
    <Stack spacing={4} width="50%">
      <Text
        textTransform="uppercase"
        fontWeight="bold"
        color="moss"
        fontSize="0.813rem"
      >
        {t('common:featured-products', 'Featured Products')}
      </Text>
      <Flex justify="flex-start" align="flex-start">
        {products &&
          products.map((product) => (
            <Box key={product.title} mr={16}>
              <Box
                as="img"
                src={product.plpImageUrl || product.primaryImageUrl}
                objectFit="contain"
                width="278px"
                height="278px"
              />
              <Text fontSize="0.875rem" my={3}>
                {product.title}
              </Text>
              <ShopifyNextLink
                href={productRoutes.detail({ slug: product.slug })}
                onClick={onClose}
              >
                <Text color="moss" fontSize={['0.875rem', null, '1rem']}>
                  {t(`products:shop-now`, 'Shop now')}&nbsp;
                  <ArrowForward />
                </Text>
              </ShopifyNextLink>
            </Box>
          ))}
      </Flex>
    </Stack>
  )
}

export default FeaturedProductsSection
