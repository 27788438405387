import { TFunction } from 'i18next'

import { ProductConcerns } from '../types'

export function getproductConcerns(t: TFunction): ProductConcerns[] {
  return [
    {
      value: 'skin-concerns',
      title: t('products:skin-concerns-all', 'All')
    },
    {
      value: 'acne-scars',
      title: t('products:acne-scars', 'Acne Scars')
    },
    {
      value: 'body-odor',
      title: t('products:body-odor', 'Body Odor')
    },
    {
      value: 'clogged-pores',
      title: t('products:clogged-pores', 'Clogged Pores')
    },
    {
      value: 'damaged-hair',
      title: t('products:damaged-hair', 'Damaged Hair')
    },
    {
      value: 'dry-hair',
      title: t('products:dry-hair', 'Dry Hair')
    },
    {
      value: 'dry-skin',
      title: t('products:dry-skin', 'Dry Skin')
    },
    {
      value: 'dull-skin',
      title: t('products:dull-skin', 'Dull Skin')
    },
    {
      value: 'enlarged-pores',
      title: t('products:enlarged-pores', 'Enlarged Pores')
    },
    {
      value: 'fine-lines',
      title: t('products:fine-lines', 'Fine Lines')
    },
    {
      value: 'irritated-skin',
      title: t('products:irritated-skin', 'Irritated Skin')
    },{
      value: 'oily-skin',
      title: t('products:oily-skin', 'Oily Skin')
    },
    {
      value: 'sun-burn',
      title: t('products:sun-burn', 'Sun Burn')
    },
    {
      value: 'sun-damage',
      title: t('products:sun-damage', 'Sun Damage')
    },
    {
      value: 'uneven-skin-tone',
      title: t('products:uneven-skin-tone', 'Uneven Skin Tone')
    },
  ]
}
