import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M30.233 0h-28.467c-0.976 0-1.767 0.791-1.767 1.767v28.468c0 0.975 0.791 1.765 1.767 1.765h15.327v-12.392h-4.171v-4.829h4.171v-3.561c0-4.133 2.524-6.384 6.212-6.384 1.767 0 3.284 0.132 3.727 0.191v4.32l-2.557 0.001c-2.005 0-2.393 0.953-2.393 2.351v3.084h4.783l-0.623 4.829h-4.16v12.391h8.155c0.973 0 1.764-0.791 1.764-1.767v-28.467c0-0.976-0.791-1.767-1.767-1.767z"
    />
  ),
})
