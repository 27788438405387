import { Box, Flex } from '@chakra-ui/core'
import dynamic from 'next/dynamic'
import React from 'react'

import { AppState } from '../../../../redux/rootReducer'
import { useTypedSelector } from '../../../../redux/store'
import { useRTL } from '../../../i18n/hooks'
import CookiePolicyBanner from '../../../shared/components/CookiePolicyBanner/CookiePolicyBanner'
import NavTimer, { NavTimerProps } from '../../../shared/components/NavTimer'
import Footer from '../Footer'
import ShopifyFooter from '../Footer/ShopifyFooter'
import ShopifyTopNav from '../ShopifyTopNav'
import TopNav from '../TopNav'

import config from 'core/config'
import { selectShowOffers } from 'modules/checkout/selectors'
import useHeaderHeight from 'modules/lumin/hooks/useHeaderHeight'
import Referral from 'modules/referral/components/Referral'

const ChromeWatcher = dynamic(
  () => import('../../../iosHelper/components/ChromeWatcher'),
  { ssr: false }
)

const px = [5, 5, 10]
export interface MainLayoutProps {
  theme?: 'light' | 'dark'
  variant?: 'original' | 'new'
  hasBorder?: boolean
  hasBoxShadow?: boolean
  enableRTL?: boolean
  hasFooter?: boolean
  announcementBarText?: string
  hasTopPadding?: boolean
  lightTopNav?: boolean
  showAnnouncementBar?: boolean
  timerConfig?: NavTimerProps
  showReferralBanner?: boolean
  store?: 'shopify' | 'next'
  showTimerSelector?: (state: AppState) => boolean
}

const MainLayout: React.FC<MainLayoutProps> = ({
  theme,
  children,
  hasBorder = true,
  hasFooter = true,
  hasBoxShadow = false,
  enableRTL = false,
  showReferralBanner = true,
  hasTopPadding = true,
  lightTopNav,
  timerConfig,
  variant,
  store,
  showTimerSelector,
}) => {
  const { dir } = useRTL()

  const headerHeight = useHeaderHeight()

  const showTimer = useTypedSelector(showTimerSelector || selectShowOffers)

  const isShopifyStore = store === 'shopify'

  const shouldAddTopPadding = hasTopPadding && !isShopifyStore

  const oneTrustScript = isShopifyStore && config('ONE_TRUST_TEMPLATE_DOMAIN')
  const pandectesStoreId = config('PANDECTES_STORE_ID')

  const useExternalCookieBanner = oneTrustScript || pandectesStoreId

  return (
    <>
      <Box dir={enableRTL ? dir : 'ltr'}>
        {showReferralBanner && <Referral />}

        <Box position="relative">
          {showTimer && timerConfig ? (
            <NavTimer {...timerConfig} />
          ) : (
            <Flex
              id="lumin-banner-root"
              order={-1}
              position="sticky"
              top={0}
              zIndex="banner"
            />
          )}
          {isShopifyStore ? (
            <ShopifyTopNav hasBorder={hasBorder} hideTopNav={showTimer} />
          ) : (
            <TopNav
              theme={theme}
              hasBorder={hasBorder}
              hasBoxShadow={hasBoxShadow}
              lightTopNav={lightTopNav}
              hideTopNav={showTimer}
            />
          )}
          <Flex
            className="layout-main"
            direction="column"
            minH="100vh"
            px={0}
            mx={0}
            pt={0}
          >
            <Box
              flexGrow={1}
              flexShrink={0}
              css={{
                '> *:first-child': {
                  // This is the height of the abs positioned topNav. Should it be in theme?
                  paddingTop: shouldAddTopPadding ? `${headerHeight}px` : 0,
                },
                '.has-header-banner + .layout-main & > .account-sys-layout': {
                  paddingTop: shouldAddTopPadding ? '8rem' : 0,
                },
                '> *': {
                  paddingLeft: px,
                  paddingRight: px,
                },
              }}
            >
              {children}
            </Box>
            {hasFooter && (
              <Box
                as={isShopifyStore ? ShopifyFooter : Footer}
                flexGrow={0}
                flexShrink={0}
                px={px}
                bg={variant === 'original' ? 'black' : 'nightGreen'}
              />
            )}
          </Flex>
          <ChromeWatcher />
          {!useExternalCookieBanner && <CookiePolicyBanner />}
        </Box>
      </Box>
      <Box id="coop-last" />
    </>
  )
}

export default MainLayout
