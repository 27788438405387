import { useEffect } from 'react'

import config from '../../../core/config'

export const toggleZendeskChatButtonDisplay = (state = 'hide') => {
  const currentChatIframe = document.querySelector(
    '[title="Button to launch messaging window"]'
  )
  currentChatIframe?.classList[state === 'hide' ? 'add' : 'remove']('d-none')
}

export function useZendeskChat({ enabled = true }) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const chatIframe = document.querySelector(
      '[title="Button to launch messaging window"]'
    )
    const apiKey = config('ZENDESK_CHAT_API_KEY')
    chatIframe?.classList.remove('d-none')
    if (!chatIframe && apiKey) {
      const scriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`

      const script = document.createElement('script')
      script.src = scriptSrc
      script.id = 'ze-snippet'
      script.async = true
      document.body.appendChild(script)
    }
    return toggleZendeskChatButtonDisplay
  }, [])
}
