import { FlexProps, Box, Flex, Text, Link } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useRTL } from '../../../i18n/hooks'
import EmailForm from '../EmailForm/EmailForm'

import { trackFooterSignup } from 'modules/lumin/events'
import staticRoutes from 'modules/static/routes'

type StayInTouchBlockProps = FlexProps & { width: number[] | string[] | string }

const StayInTouchBlock: React.FC<StayInTouchBlockProps> = ({ width }) => {
  const { t } = useTranslation()
  const { isRTL } = useRTL()

  return (
    <Flex
      direction="column"
      pr={isRTL ? 0 : [0, 2, 5]}
      width={width}
      wrap="wrap"
      flexShrink={0}
    >
      <Box maxWidth="361px" flexShrink={0} mb={2}>
        <Text
          mb={[4, 3, 3]}
          fontSize="0.75rem"
          lineHeight="1rem"
          fontWeight="bold"
        >
          {t('common:join', 'Join')} #LUMIN
        </Text>
        <EmailForm onFormSubmit={(values) => trackFooterSignup(values.email)} />
        <Text mt={8} size="md">
          {t(
            'footer:signup-for-our-newsletter',
            'Sign up for our newsletter to receive updates on new products, events, offers, and more. Unsubscribe anytime.'
          )}
          &nbsp;
          {t('common:view-our', 'View our')}
          &nbsp;
          <Link textDecor="underline" href={staticRoutes.privacyPolicy()}>
            {t('common:privacy-policy', 'Privacy Policy')}
          </Link>
          &nbsp; &#38; &nbsp;
          <Link textDecor="underline" href={staticRoutes.termsOfService()}>
            {t('common:terms-of-service', 'Terms of Service')}
          </Link>
          .
        </Text>
      </Box>
    </Flex>
  )
}

export default StayInTouchBlock
