import {
  Text,
  Box,
  Flex,
  Stack,
  useTheme,
  Collapse,
  FlexProps,
  useDisclosure,
} from '@chakra-ui/core'
import { keyframes } from '@emotion/core'
import { noop } from '@pangaea-holdings/pangaea-checkout'
import { FC } from 'react'

import { useRTL } from '../../../i18n/hooks'
import { NavMenuItem as MenuItem } from './menuItems'

import { ChevronLeft } from 'modules/shared/components/Icons/ChevronLeft'
import { ChevronRight } from 'modules/shared/components/Icons/ChevronRight'
import ShopifyNextLink from 'modules/shared/components/ShopifyNextLink'

type NavItemProps = FlexProps & {
  title: string
  isLink?: boolean
  href?: string
  open?: boolean
  onClose?: () => void
}

export const NavItem: FC<NavItemProps> = ({
  isLink,
  title,
  onClick,
  onClose,
  href = '',
  open = false,
  ...props
}) => {
  const { isRTL } = useRTL()
  const theme = useTheme()
  const ArrowComponent = isRTL ? ChevronRight : ChevronLeft
  const arrowDeg = isRTL ? 'rotate(90deg)' : 'rotate(-90deg)'

  return (
    <Flex
      as={isLink ? ShopifyNextLink : undefined}
      onClick={isLink ? onClose : onClick}
      align="center"
      justify="space-between"
      direction={isRTL ? 'row-reverse' : 'row'}
      {...{ ...props, href: isLink ? href : undefined }}
    >
      <Text fontSize="0.875rem" fontWeight="inherit" color="moss">
        {title}
      </Text>
      {!isLink && <ArrowComponent
        transform={open ? arrowDeg : undefined}
        transition={`${theme.durations.quick} ${theme.easings.default}`}
      />}
    </Flex>
  )
}

export interface NavMenuItemProps {
  title: string
  href?: string
  menuItems?: MenuItem[]
  onClose?: () => void
  setActiveSubMenus?: (subMenu: MenuItem) => void
}

const NavMenuItem: FC<NavMenuItemProps> = ({
  title,
  href = '',
  menuItems,
  onClose,
  setActiveSubMenus = noop,
}) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box py={4} borderBottom="0.5px solid" borderColor="seastone">
      <NavItem
        title={title}
        isLink={!menuItems}
        onClose={onClose}
        onClick={onToggle}
        textTransform="uppercase"
        href={href}
        open={isOpen}
      />
      {menuItems && (
        <Collapse isOpen={isOpen}>
          <Stack mt={4} px={2} spacing={5}>
            {menuItems.map((menuItem) => (
              <NavItem
                key={menuItem.title}
                title={menuItem.title}
                isLink={!!menuItem.href}
                onClick={() => setActiveSubMenus(menuItem)}
                href={menuItem.href}
                onClose={onClose}
              />
            ))}
          </Stack>
        </Collapse>
      )}
    </Box>
  )
}

export default NavMenuItem
