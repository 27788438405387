import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const DiscountBadgeIcon = createIcon({
  displayName: 'DiscountBadgeIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M5.33339 5.86667H6.40005M9.60005 10.1333H10.6667M10.6667 5.33333L5.33339 10.6667M7.25445 0.842667L6.05019 2.0448C5.85282 2.24252 5.58502 2.35378 5.30565 2.35413H3.40912C3.12962 2.35413 2.86156 2.46517 2.66392 2.6628C2.46629 2.86044 2.35525 3.1285 2.35525 3.408V5.30453C2.3549 5.5839 2.24364 5.8517 2.04592 6.04907L0.842721 7.25547C0.645161 7.4531 0.53418 7.7211 0.53418 8.00053C0.53418 8.27997 0.645161 8.54797 0.842721 8.7456L2.04805 9.94987C2.14581 10.0476 2.22335 10.1637 2.27624 10.2915C2.32913 10.4192 2.35634 10.5561 2.35632 10.6944V12.5909C2.35632 13.1733 2.82779 13.6448 3.41019 13.6448H5.30672C5.58619 13.6448 5.85392 13.7557 6.05125 13.9541L7.25659 15.1584C7.45422 15.356 7.72222 15.4669 8.00165 15.4669C8.28109 15.4669 8.54909 15.356 8.74672 15.1584L9.95099 13.9531C10.1485 13.7557 10.4163 13.6449 10.6955 13.6448H12.5921C12.8716 13.6448 13.1396 13.5338 13.3373 13.3361C13.5349 13.1385 13.6459 12.8704 13.6459 12.5909V10.6944C13.6459 10.4149 13.7569 10.1472 13.9553 9.94987L15.1595 8.74453C15.3571 8.54691 15.4681 8.27891 15.4681 7.99947C15.4681 7.72003 15.3571 7.45203 15.1595 7.2544L13.9542 6.05013C13.7569 5.85259 13.646 5.58482 13.6459 5.3056V3.40907C13.6459 3.12956 13.5349 2.86151 13.3373 2.66387C13.1396 2.46623 12.8716 2.3552 12.5921 2.3552H10.6955C10.4162 2.35485 10.1484 2.24359 9.95099 2.04587L8.74459 0.842667C8.54696 0.645108 8.27896 0.534126 7.99952 0.534126C7.72008 0.534126 7.45208 0.645108 7.25445 0.842667V0.842667Z"
      stroke="black"
      strokeWidth="1.5"
    />
  ),
})
