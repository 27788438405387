import { SimpleGrid } from '@chakra-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getSupportMenuItems } from '../LeftNav/menuItems'
import NavCard from './NavCard'

const SupportNavContent = () => {
  const { t } = useTranslation()

  return (
    <SimpleGrid m="auto" maxW="1440px" width="100%" columns={4}>
      {getSupportMenuItems(t).map((link) => {
        return <NavCard key={link.href} {...link} />
      })}
    </SimpleGrid>
  )
}

export default SupportNavContent
