import React from 'react'
import { useTranslation } from 'react-i18next'

import ShopifyNextLink from './ShopifyNextLink'
interface PrivacyPolicyLinkProps {
  mr?: number
  ml?: number
}

const PrivacyPolicyLink: React.FC<PrivacyPolicyLinkProps> = ({ ...other }) => {
  const { t } = useTranslation()

  return (
    <ShopifyNextLink
      href="/pages/privacy-policy"
      textDecoration="underline"
      {...other}
    >
      {t('footer:privacy-policy', 'Privacy Policy')}
    </ShopifyNextLink>
  )
}

export default PrivacyPolicyLink
