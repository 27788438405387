import { TFunction } from 'i18next'

import { apiRouteFetch } from '../../api'
import { hydrateSingleProductDetail } from '../hydration'
import { ApiProductDetails, ProductDetails } from '../types'

export const freeShippingFromAmount = 2500

export const defaultSubscriptionInterval = '2month'

export const CLEARANCE_CATALOG_COUNTRY = 'TG'

/**
 * Returns a list of product details. Only products that appear here will be displayed on the PLP and PDP
 */
export async function getAllProductDetails(
  t: TFunction
): Promise<ProductDetails[]> {
  const apiProductDetails = await apiRouteFetch<ApiProductDetails[]>(
    '/products/details'
  )
  return apiProductDetails.map((d) => hydrateSingleProductDetail(t, d))
}

export const getProductDetails = (() => {
  let details: ProductDetails[] | null = null

  return async (t: TFunction, id: number): Promise<ProductDetails | null> => {
    if (!details) {
      details = await getAllProductDetails(t)
    }

    return details.find((a) => a.id === id) ?? null
  }
})()
