import {
  Flex,
  Box,
  Text,
  Grid,
  CloseButton,
  Stack,
  StackDivider,
} from '@chakra-ui/core'
import Image from 'next/image'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import logo from '../../../../../public/img/logo.png'
import { Drawer } from '../../../../design/components'
import { useRTL } from '../../../i18n/hooks'
import { NavTab } from '../../types'
import {
  getAboutMenuItems,
  getShopMenuItems,
  getSupportMenuItems,
  NavMenuItem as MenuItem,
} from './menuItems'
import NavMenuItem, { NavItem } from './NavMenuItem'

import { ChevronRight } from 'modules/shared/components/Icons/ChevronRight'
import ShopifyNextLink from 'modules/shared/components/ShopifyNextLink'

export interface LeftNavProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  setLeftNavTab: (val: NavTab) => void
  leftNavTab: NavTab
}

const LeftNav: FC<LeftNavProps> = ({
  leftNavTab,
  setLeftNavTab,
  ...drawerProps
}) => {
  const [activeSubMenus, setActiveSubMenus] = React.useState<MenuItem | null>(
    null
  )

  const { t } = useTranslation()
  const { isRTL, dir } = useRTL()

  const hideDrawer = () => {
    drawerProps.setIsOpen(false)
  }

  return (
    <Drawer
      anchor={isRTL ? 'right' : 'left'}
      dir={dir}
      {...drawerProps}
      desktopWidth={550}
      closeButton={false}
    >
      <Flex direction="column" flex={1}>
        <Grid
          px={6}
          py={2}
          borderBottom="1px solid"
          borderColor="seastone"
          bgColor="white"
          templateColumns="18px auto"
        >
          <CloseButton onClick={hideDrawer} />
          <Box m="auto">
            <ShopifyNextLink href="/">
              <Image alt="logo" width={170} height={49} src={logo} />
            </ShopifyNextLink>
          </Box>
        </Grid>
        <Flex
          pt={4}
          pb={8}
          px={6}
          flex={1}
          direction="column"
          bgColor="white"
          overflowY="auto"
        >
          <Flex
            direction="column"
            display={activeSubMenus ? 'none' : undefined}
          >
            <NavMenuItem
              title={t('nav:shop', 'Shop')}
              menuItems={getShopMenuItems(t)}
              setActiveSubMenus={setActiveSubMenus}
              onClose={hideDrawer}
            />
            <NavMenuItem
              title={t('nav:get-a-free-trial', 'Get a Free Trial')}
              href="/pages/why-men-love-lumin?startQuiz=true"
            />
            <NavMenuItem
              title={t('nav:reviews', 'Reviews')}
              href="/pages/customer-reviews"
            />
            <NavMenuItem
              title={t('nav:about', 'About')}
              menuItems={getAboutMenuItems(t)}
              onClose={hideDrawer}
            />
            <NavMenuItem
              title={t('nav:support', 'Support')}
              menuItems={getSupportMenuItems(t)}
              onClose={hideDrawer}
            />
            <NavMenuItem title={t('nav:blog', 'Blog')} href="/blog" />
            <NavMenuItem
              title={t('nav:my-account', 'My Account')}
              href="?account-menu=true"
            />
          </Flex>
          {activeSubMenus && (
            <Flex direction="column">
              <Grid position="relative" placeItems="center" py={4}>
                <ChevronRight
                  onClick={() => setActiveSubMenus(null)}
                  pos="absolute"
                  left="0"
                  fontSize="initial"
                />
                <Text fontWeight="bold" m="auto" textTransform="uppercase">
                  {activeSubMenus.title}
                </Text>
              </Grid>
              <Stack pt={8} spacing={6}>
                {activeSubMenus.subMenus?.map((menu) => (
                  <NavItem
                    key={menu.title}
                    isLink
                    {...menu}
                    onClose={hideDrawer}
                  />
                ))}
              </Stack>
            </Flex>
          )}
        </Flex>
        {!activeSubMenus && (
          <Stack
            h="56px"
            py={4}
            px={6}
            spacing={4}
            direction="column"
            divider={<StackDivider borderColor="seastone" />}
          >
            <NavItem
              isLink
              textTransform="uppercase"
              title={t('common:contact-us', 'Contact Us')}
              href="/pages/customer-support-portal"
              onClose={hideDrawer}
            />
          </Stack>
        )}
      </Flex>
    </Drawer>
  )
}

export default LeftNav
