import {
  Input,
  Box,
  FormControl,
  Text,
  Flex,
  Image,
  InputRightElement,
} from '@chakra-ui/core'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../design/components/Modal/Modal'
import { isValidEmail } from '../../../../utils/validations'
import { ArrowForward } from '../../../shared/components/Icons/ArrowForward'
import EmailSubscriptionSuccess from './EmailSubscriptionSuccess'

interface EmailFormValues {
  email: string
}

interface EmailFormProps {
  onFormSubmit?: (values: EmailFormValues) => void
  dark?: boolean
}

const EmailForm: React.FC<EmailFormProps> = ({ onFormSubmit, dark }) => {
  const { t } = useTranslation()
  const { handleSubmit, errors, register, clearErrors, reset } = useForm()
  const [showButton, setShowButton] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  function validateEmail(value) {
    let error
    if (!value) {
      error = t('footer:email-is-required', 'Email is required')
    } else if (!isValidEmail(value)) {
      error = t('footer:invalid-email', 'Invalid Email')
    }
    return error || true
  }

  function onSubmit(values: EmailFormValues) {
    if (onFormSubmit) {
      onFormSubmit(values)
    }
    setIsModalOpen(true)
    reset({ email: null })
    setShowButton(false)
  }

  return (
    <>
      <Box width="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          onBlur={() => {
            clearErrors('email')
          }}
        >
          <Flex align="center">
            <FormControl>
              <Input
                size="md"
                type="email"
                name="email"
                variant={dark ? 'outline' : 'underline'}
                color={dark ? 'nightGreen' : 'whiteSands'}
                borderColor={dark ? 'nightGreen' : 'whiteSands'}
                borderRadius={dark ? '4px' : '0'}
                px={dark ? 2 : 0}
                _focus={{ borderColor: dark ? 'nightGreen' : 'whiteSands' }}
                _placeholder={{
                  color: dark ? 'nightGreen' : 'whiteSands',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  letterSpacing: '0.7px',
                }}
                _autofill={{ px: 1 }}
                placeholder={t('footer:email-address', 'EMAIL ADDRESS')}
                ref={register({ validate: validateEmail })}
                onChange={(event) => {
                  const value = event.target.value
                  if (value && isValidEmail(value)) {
                    setShowButton(true)
                  } else {
                    setShowButton(false)
                  }
                }}
              />

              {showButton && (
                <InputRightElement>
                  <button type="submit">
                    <Flex
                      w="32px"
                      h={['32px', '42px']}
                      justify="center"
                      align="center"
                      px="6px"
                      py="1px"
                    >
                      {dark ? (
                        <ArrowForward fontSize="lg" />
                      ) : (
                        <Image src="https://cdn.shopify.com/s/files/1/0044/1237/5107/files/right-arrow.png?v=1588345221" />
                      )}
                    </Flex>
                  </button>
                </InputRightElement>
              )}
            </FormControl>
          </Flex>
          <Text size="sm">{errors.email && errors.email.message}</Text>
        </form>
      </Box>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <EmailSubscriptionSuccess onButtonClick={() => setIsModalOpen(false)} />
      </Modal>
    </>
  )
}

export default EmailForm
