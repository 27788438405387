import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'

import { useRTL } from '../../i18n/hooks'
import { FacebookIcon } from './Icons/Facebook'
import { InstagramIcon } from './Icons/Instagram'
import { TwitterIcon } from './Icons/Twitter'

type SocialMediaLinksProps = FlexProps & {
  width: number[] | string[] | string
  size?: string
}

type SocialMediaLinkProps = BoxProps & {
  href: string
  label: string
}

export const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({
  href,
  label,
  children,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Box
        as="a"
        target="_blank"
        rel=" noopener noreferrer"
        display="inline-block"
        href={href}
        transition="0.3s"
        _hover={{
          transform: 'scale(1.08)',
        }}
        aria-label={label}
      >
        {children}
      </Box>
    </Box>
  )
}

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({
  width,
  size = 'lg',
}) => {
  const { isRTL } = useRTL()

  return (
    <Flex direction="row" mt={2} width={width} flexShrink={0} mb={[6, 6, 0]}>
      <SocialMediaLink
        mr={!isRTL ? (size === 'lg' ? 5 : 3) : 0}
        ml={isRTL ? (size === 'lg' ? 5 : 3) : 0}
        href="https://www.instagram.com/lumin.skin/"
        label="instagram"
      >
        <InstagramIcon
          w={size === 'lg' ? '29px' : '20px'}
          h={size === 'lg' ? '32px' : '20px'}
        />
      </SocialMediaLink>
      <SocialMediaLink
        mr={!isRTL ? (size === 'lg' ? 5 : 3) : 0}
        ml={isRTL ? (size === 'lg' ? 5 : 3) : 0}
        href="https://www.facebook.com/lumin.skincare/"
        label="facebook"
      >
        <FacebookIcon
          w={size === 'lg' ? '29px' : '20px'}
          h={size === 'lg' ? '32px' : '20px'}
        />
      </SocialMediaLink>
      <SocialMediaLink
        ml={isRTL ? (size === 'lg' ? 5 : 3) : 0}
        href="https://twitter.com/lumin_skin"
        label="twitter"
      >
        <TwitterIcon
          w={size === 'lg' ? '40px' : '20px'}
          h={size === 'lg' ? '32px' : '20px'}
        />
      </SocialMediaLink>
    </Flex>
  )
}

export default SocialMediaLinks
