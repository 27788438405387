import buildRoutes, { routeFromUrl } from '../shared/buildRoutes'

const howToRoutes = buildRoutes('pages/how-to', {
  howTo: ({ filter }: { filter?: string } = {}) => {
    if (!filter) {
      return ''
    }
    return `?filter=${filter}`
  },
  set: routeFromUrl<{ slug: string }>('[slug]'),
})

export default howToRoutes
