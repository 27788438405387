import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const RepeatIcon2 = createIcon({
  displayName: 'RepeatIcon2',
  viewBox: '0 0 14 14',
  path: (
    <path d="M5 4H2.39C3.03668 3.00616 3.98721 2.24792 5.09991 1.83829C6.21262 1.42866 7.4279 1.38959 8.56462 1.72689C9.70134 2.0642 10.6986 2.75982 11.4078 3.71007C12.117 4.66031 12.5001 5.8143 12.5 7H13.5C13.5014 5.66275 13.0903 4.35761 12.3227 3.26256C11.5552 2.1675 10.4686 1.33583 9.21109 0.880937C7.95358 0.426046 6.58637 0.370081 5.29589 0.720671C4.00541 1.07126 2.85447 1.81134 2 2.84V1H1V5H5V4ZM9 10H11.61C10.9633 10.9938 10.0128 11.7521 8.90009 12.1617C7.78739 12.5713 6.5721 12.6104 5.43539 12.2731C4.29867 11.9358 3.30139 11.2402 2.59221 10.2899C1.88303 9.33969 1.49993 8.18571 1.5 7H0.500003C0.498627 8.33726 0.909734 9.6424 1.67727 10.7374C2.4448 11.8325 3.53141 12.6642 4.78892 13.1191C6.04642 13.574 7.41363 13.6299 8.70411 13.2793C9.99459 12.9287 11.1455 12.1887 12 11.16V13H13V9H9V10Z" />
  ),
})
