import React from 'react'

import useReferralCode from '../hooks/useReferralCode'
import ReferralGift from './ReferralGift'

const Referral = () => {
  const code = useReferralCode()

  return code ? <ReferralGift /> : null
}

export default Referral
