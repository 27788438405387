import { Flex } from '@chakra-ui/core'
import {
  AfterpayIcon,
  AffirmIcon,
  KlarnaIcon,
  GiropayIcon,
  SepaDirectDebitIcon,
  IDealIcon,
  BancontactIcon,
  GrabpayIcon,
  MayBankIcon,
  BCAIcon,
  MandiriIcon,
  BNIIcon,
  MBWayIcon,
  MultibancoIcon,
  PayconiqIcon,
  TwintIcon,
  usePaymentProviders,
} from '@pangaea-holdings/pangaea-checkout'
import React from 'react'

import { useFeatureFlag } from '../../../optimizely/hooks/useFeatureFlag'
import { Amex } from '../../../shared/components/Icons/Amex'
import { Discover } from '../../../shared/components/Icons/Discover'
import { Mastercard } from '../../../shared/components/Icons/Mastercard'
import { Visa } from '../../../shared/components/Icons/Visa'

const FooterCards = () => {
  const {
    isAfterpayAvailable,
    isKlarnaAvailable,
    isGiropayAvailable,
    isSepaDirectDebitAvailable,
    isIDealAvailable,
    isBancontactAvailable,
    isAffirmAvailable,
    isGrabpayAvailable,
    isMolpayAvailable,
    isDokuBCAAvailable,
    isDokuMandiriAvailable,
    isDokuBNIAvailable,
    isMBWayAvailable,
    isPayconiqAvailable,
    isTwintAvailable,
    isMultibancoAvailable,
  } = usePaymentProviders()

  const enableAfterpay = useFeatureFlag('ENABLE_AFTERPAY', true)
  const enableAffirm = useFeatureFlag('ENABLE_AFFIRM', true)
  const enableKlarna = useFeatureFlag('ENABLE_KLARNA', true)
  const enableGiropay = useFeatureFlag('ENABLE_GIROPAY', false)
  const enableIDeal = useFeatureFlag('ENABLE_IDEAL', false)
  const enableBancontact = useFeatureFlag('ENABLE_BANCONTACT', false)
  const enableGrabpay = useFeatureFlag('ENABLE_GRABPAY', false)
  const enableMolpay = useFeatureFlag('ENABLE_MOLPAY', false)
  const enableDoku = useFeatureFlag('ENABLE_DOKU', false)
  const enableMBWay = useFeatureFlag('ENABLE_MBWAY', false)
  const enableMultibanco = useFeatureFlag('ENABLE_MULTIBANCO', false)
  const enableTwint = useFeatureFlag('ENABLE_TWINT', false)
  const enableSepaDirectDebit = useFeatureFlag(
    'ENABLE_SEPA_DIRECT_DEBIT',
    false
  )

  return (
    <Flex align="center" fontSize="3em" flex="1">
      <Visa />
      <Amex />
      <Mastercard />
      <Discover />
      {enableAfterpay && isAfterpayAvailable && (
        <AfterpayIcon ml="1px" mt="-1px" w="35px" mr="3px" />
      )}
      {enableAffirm && isAffirmAvailable && (
        <AffirmIcon
          height="21px"
          w="39px"
          borderRadius="2px"
          ml="unset"
          border="none"
          backgroundColor="white"
        />
      )}
      {enableKlarna && isKlarnaAvailable && (
        <KlarnaIcon
          w="37px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableGiropay && isGiropayAvailable && (
        <GiropayIcon w="55px" mr="3px" mt="-1px" />
      )}
      {enableSepaDirectDebit && isSepaDirectDebitAvailable && (
        <SepaDirectDebitIcon
          w="37px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableIDeal && isIDealAvailable && (
        <IDealIcon
          w="38px"
          h="22px"
          px="5px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableBancontact && isBancontactAvailable && (
        <BancontactIcon h="22px" mr="3px" mt="-1px" />
      )}
      {enableGrabpay && isGrabpayAvailable && (
        <GrabpayIcon
          h="22px"
          w="37px"
          mr="3px"
          mt="-1px"
          objectFit="cover"
          borderRadius="3px"
        />
      )}
      {enableMolpay && isMolpayAvailable && (
        <MayBankIcon
          w="39px"
          h="22px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
        />
      )}
      {enableDoku && isDokuBCAAvailable && (
        <BCAIcon
          w="38px"
          h="22px"
          px="5px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableDoku && isDokuMandiriAvailable && (
        <MandiriIcon
          w="39px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableDoku && isDokuBNIAvailable && (
        <BNIIcon
          w="39px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableBancontact && isPayconiqAvailable && (
        <PayconiqIcon
          w="42px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableTwint && isTwintAvailable && (
        <TwintIcon
          w="39px"
          h="22px"
          px="2px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="black"
        />
      )}
      {enableMultibanco && isMultibancoAvailable && (
        <MultibancoIcon
          w="39px"
          h="22px"
          py="2px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
      {enableMBWay && isMBWayAvailable && (
        <MBWayIcon
          w="39px"
          h="22px"
          px="3px"
          mr="3px"
          mt="-1px"
          borderRadius="3px"
          backgroundColor="white"
        />
      )}
    </Flex>
  )
}

export default FooterCards
