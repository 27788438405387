import {
  CheckoutContext,
  getUserCountryCode,
  Product,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/browser'
import { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch } from '../../../redux/store'
import { useFeatureFlag } from '../../optimizely/hooks/useFeatureFlag'
import { fetchAllProductsCached } from '../actions'
import { getAllProductDetails } from '../constants/productDetails'
import { mergeProductWithDetails } from '../functions'
import { ProductWithDetails, ProductRenewalDiscountRule } from '../types'

type UseProductsConfig = {
  excludeHiddenProducts?: boolean
  productIds?: number[]
}

function excludeProductsWithNoVisibleOptions(
  products: ProductWithDetails[],
  countryCode: string
): ProductWithDetails[] {
  const [productsWithVisibleOption, productsWithNoVisibleOption] =
    products.reduce<Array<ProductWithDetails[]>>(
      (filteredProducts, product) => {
        const hasVisibleOption = Object.values(product.sizes.options).some(
          (size) => size.isVisible
        )
        hasVisibleOption
          ? filteredProducts[0].push(product)
          : filteredProducts[1].push(product)

        return filteredProducts
      },
      [[], []]
    )

  if (productsWithNoVisibleOption.length) {
    Sentry.captureException(
      `Found product(s) with missing options. ids=${productsWithNoVisibleOption
        .map((p) => p.id)
        .join(',')}. countryCode=${countryCode}`
    )
  }

  return productsWithVisibleOption
}

export function useProducts(config?: UseProductsConfig) {
  const { t } = useTranslation()
  const { state } = useContext(CheckoutContext)
  const [products, setProducts] = useState<ProductWithDetails[] | null>(null)
  const [isError, setIsError] = useState(false)
  const reduxDispatch = useTypedDispatch()
  const renewalDiscountRule: ProductRenewalDiscountRule = useFeatureFlag(
    'RENEWAL_DISCOUNT_RULE'
  )

  const selectedCurrency = state.cart.selectedCurrency

  useEffect(() => {
    setIsError(false)
    let didCancelFetch = false
    const loadProducts = async () => {
      let countryCode

      try {
        countryCode =
          state.countries.selectedCountryId || (await getUserCountryCode())
      } catch (e) {
        Sentry.captureException('Failed to fetch countryCode ', e)
      }

      try {
        const prods = await reduxDispatch(
          fetchAllProductsCached(
            selectedCurrency,
            countryCode,
            renewalDiscountRule
              ? [renewalDiscountRule?.subscriptionDiscountKey]
              : undefined
          )
        )
        const productDetails = config?.productIds
          ? (await getAllProductDetails(t)).filter((detail) => {
              return config.productIds?.includes(detail.id)
            })
          : await getAllProductDetails(t)

        if (!didCancelFetch) {
          setProducts(
            excludeProductsWithNoVisibleOptions(
              mergeProductWithDetails(
                prods,
                productDetails,
                t,
                config?.excludeHiddenProducts
              ),
              countryCode
            )
          )
        }
      } catch (e) {
        console.error('useProducts: ', e)
        setIsError(true)
        Sentry.captureException('Error fetching products', e)
      }
    }
    loadProducts()

    return () => {
      didCancelFetch = true
    }
  }, [
    selectedCurrency,
    renewalDiscountRule?.subscriptionDiscountKey,
    config?.excludeHiddenProducts,
    config?.productIds,
  ])

  return { products, isError }
}
