import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const UserIcon = createIcon({
  displayName: 'UserIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="M12 15C15.3137 15 18 12.3137 18 8.99997C18 5.68626 15.3137 2.99997 12 2.99997C8.68629 2.99997 6 5.68626 6 8.99997C6 12.3137 8.68629 15 12 15Z" stroke="black" strokeWidth="1.49508" strokeMiterlimit="10"/>
      <path d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493" stroke="black" strokeWidth="1.49508" strokeLinecap="round" strokeLinejoin="round"/>
    </>
  ),
})
