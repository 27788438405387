import { Box, Flex, Text, Heading } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CancelIcon } from 'modules/shared/components/Icons/Cancel'

const ReferralGift = () => {
  const [hide, setHide] = useState(false)
  const hideGift = () => setHide(true)

  const { t } = useTranslation()

  if (hide) {
    return null
  }

  return (
    <Box
      position="fixed"
      right="0"
      bottom="0"
      width={['100%', null, '450px']}
      bg="mabel"
      zIndex="banner"
    >
      <Flex
        padding={8}
        align="stretch"
        width="100%"
        justify="space-between"
        position="relative"
      >
        <Flex
          direction="column"
          justify="center"
          width={['60%', null, '180px']}
        >
          <Text
            mb="10px"
            fontSize="12px"
            letterSpacing="0.5px"
            fontWeight="bold"
          >
            {t('common:free-gift', 'FREE GIFT')}
          </Text>
          <Heading fontSize={['18px', null, '25px']} lineHeight="27px">
            {t(
              'product:after-hours-recovery-oil-for-men',
              'After Hours Recovery Oil for Men'
            )}
          </Heading>
          <Text mt={8} fontSize="14px" color="darkGray">
            {t('product:free-gift-applied-on-checkout', 'Gift will be automatically applied at checkout')}
          </Text>
        </Flex>
        <Box width={['30%', null, '200px']}>
          <Box
            as="img"
            src="https://d1b929y2mmls08.cloudfront.net/luminskin/img/products/recovery-oil/0.png"
            width="100%"
            maxHeight="150px"
            objectFit="contain"
          />
        </Box>
        <CancelIcon
          position="absolute"
          top="15px"
          right="15px"
          width="15px"
          cursor="pointer"
          onClick={hideGift}
        />
      </Flex>
    </Box>
  )
}

export default ReferralGift
