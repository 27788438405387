import {
  CartResponse,
  CartResponseItem,
} from '@pangaea-holdings/pangaea-checkout'

import { CLEARANCE_ITEM_MAP } from './clearanceItems'

export const isClearanceCartItem = (cartItem: CartResponseItem) => {
  const itemCleranceInfo = CLEARANCE_ITEM_MAP[cartItem.product.id]

  if (!itemCleranceInfo) {
    return false
  }

  if (!itemCleranceInfo.variants) {
    return true
  }

  return Object.keys(itemCleranceInfo.variants).reduce((isSelected, option) => {
    if (option === 'sizes') {
      isSelected =
        isSelected &&
        itemCleranceInfo.variants.sizes.includes(cartItem.selectedSize.id)
    } else {
      isSelected =
        isSelected &&
        itemCleranceInfo.variants[option].includes(
          cartItem.selectedCustomizationOptions[option]?.id
        )
    }
    return isSelected
  }, true)
}

export const getNonClearanceCartItems = (cart: CartResponse) => {
  return cart.items.filter(
    (item) => item.subscription || !isClearanceCartItem(item)
  )
}
