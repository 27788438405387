export const freeTrialPostPurchaseApplicableProducts = [
  10, // Age Management Set
  13, // Classic Maintenance Set
  25, // Correction Trio Sets
  58,
  140, //Anti-Fatigue Essentials Set (id:58 prod) (id:140 dev)
  99, 
  582, // Anti Fatigue Essentials Set with SPF
  100, 
  583, // Correction Trio with SPF
  103,
  580, // Age Management with SPF
  106, 
  581 // Classic Maintenance with SPF
]


export const freeTrialUVSets = [99, 100, 103, 106, 582, 583, 580, 581]
export const freeTrialLegacySets = [10, 13, 25, 58, 140]
export const hiddenCustomizationOptions = ['Age Bracket']