import { TFunction } from 'i18next'

import { ProductsPageDetail } from '../types'

export function getProductsPageDetails(t: TFunction): ProductsPageDetail[] {
  return [
    {
      value: 'all',
      title: t('common:all', 'All'),
      titleTag: t('common:all-products', 'All Products'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/HERO_1264x352_0dfec503-d85d-4a9b-a0aa-d0b92cae2727.jpg?v=1637235066',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/HERO_375x249_7231abb1-ae88-4792-9cfe-d2a5194ecd71.jpg?v=1637235066',
      header: t('products:new-to-skin-care', 'New to Skincare?'),
      subHeader: t(
        'products:unlock-your-personalized-skincare',
        'Unlock your personalized skincare routine today.'
      ),
      description: t(
        'products:discover-premium-mens-skincare-products-and-bundles',
        'Discover premium men’s skincare products for your unique needs. Shop all products and bundles needed to get on top of your personal care routine.'
      ),
      hasButton: true,
      centerHeoroHeader: true,
    },
    {
      value: 'best-sellers',
      title: t('common:best-sellers', 'Best Sellers 🎉'),
      titleTag: t('products:bestsellers', 'Bestsellers'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/BEST_SELLERS_1264x352_21fedf86-1e3c-45b1-9ddc-0e2ecc5a3f47.jpg?v=1637235020',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/BEST_SELLERS_357x249_1bd18be2-4540-4890-b769-c8441c740160.jpg?v=1637235020',
      header: t('common:best-sellers', 'Best Sellers 🎉'),
      subHeader: t(
        'products:our-best-selling-skincare-product',
        'Our best-selling skincare products deliver results you can trust––for a face you have to feel to believe.'
      ),
      description: t(
        'products:uncover-vibrant-skin-with-products-made-to-meet-your-needs',
        'Uncover vibrant skin with products made to meet your needs. Discover why men love our best-selling skincare products––and get results you have to see to believe.'
      ),
      filterNavFixedPositionOrigin: 463,
    },
    {
      value: 'face',
      title: t('common:face', 'Face'),
      titleTag: t('common:face', 'Face'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/SKIN_1264x352_712e6992-b3ad-465f-8a47-f84842e846d2.jpg?v=1637234958',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/SKIN_375x249_502c2777-523e-48a8-a6c9-ef5fd6dedff2.jpg?v=1637234958',
      header: t('common:face', 'Face'),
      subHeader: t(
        'products:mens-skincare-should-be-simple-but-effective',
        "Men's skincare should be simple but effective. Discover how the right products can help you put your best face forward."
      ),
      description: t(
        'products:shop-the-new-skincare-classics-for-men',
        "Shop the new skincare classics for men. Our popular men's skincare products feature cutting-edge ingredients that bring out the best in men’s skin, time and time again."
      ),
      highlights: [
        {
          title: t('products:fast-plus-imple', 'Fast + Simple'),
          description: t(
            'products:get-your-best-skin-in-few-minutes',
            'Get your best skin in just a few minutes a day.'
          ),
        },
        {
          title: t(
            'products:cutting-edge-ingredients',
            'Cutting-Edge Ingredients'
          ),
          description: t(
            'products:we-source-the-worlds-best-ingredients',
            'We source the world’s best ingredients for men’s skin.'
          ),
        },
        {
          title: t('products:powerful-results', 'Powerful Results'),
          description: t(
            'products:discover-how-great-your-skin',
            'Discover how great your skin can feel and look.'
          ),
        },
      ],
      navFixedPositionOrigin: 665,
      filterNavFixedPositionOrigin: 463,
    },
    {
      value: 'hair-and-body',
      title: t('common:hair-and-body', 'Hair & Body'),
      titleTag: t('common:hair-and-body', 'Hair & Body'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/HAIR_BODY_1264x352_b6b07fe1-76a9-47ff-bd08-328991bb667c.jpg?v=1637234900',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/HAIR_BODY_375x249_91894cf6-b714-4619-9497-3247459e0c93.jpg?v=1637234900',
      header: t('common:hair-and-body', 'Hair & Body'),
      subHeader: t(
        'products:the-rest-of-your-body-deserves-care',
        'The rest of your body deserves the same care you give to your face.'
      ),
      description: t(
        'products:achieve-amazing-results-from-head-to-toe',
        'Achieve amazing results from head to toe with high-impact products that work hard for you. And our men’s body wash is a must. We see no need to mess with the best.'
      ),
      highlights: [
        {
          title: t('products:body-care', 'Body Care'),
          description: t(
            'products:feel-fresh-and-renewed',
            'Feel fresh and renewed from head to toe.'
          ),
        },
        {
          title: t('products:stronger-hair', 'Stronger Hair'),
          description: t(
            'products:nourish-your-mane-and-optimize-growth',
            'Nourish your mane and optimize growth.'
          ),
        },
        {
          title: t('products:premium-ingredients', 'Premium Ingredients'),
          description: t(
            'products:we-source-only-the-best-to-deliver',
            'We source only the best to deliver real results.'
          ),
        },
      ],
      navFixedPositionOrigin: 665,
      filterNavFixedPositionOrigin: 463,
    },
    {
      value: 'bundles',
      title: t('common:bundles', 'Bundles'),
      titleTag: t('common:bundles', 'Bundles'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/SETS_1264x352_b329b641-2201-455c-8ac9-01a2ff3eb731.jpg?v=1637234834',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/SETS_375x249_f85d8066-113d-4d78-99e2-4f47a68df5fa.jpg?v=1637234834',
      header: t('common:bundles', 'Bundles'),
      subHeader: t(
        'products:our-bundles-makes-your-skincare-routine-simple',
        'Our bundles make your skincare routine simple, straightforward, and easy. No matter what your skincare goals are, we’ve got a bundle for that.'
      ),
      description: t(
        'products:get-apersonalized-skincare-routine-bundle',
        'Get a personalized skincare routine or upgrade your shower essentials with our high-performance bundle. Whether you’re new to skincare or want to see better results, we can help you meet your goals in just a few minutes a day.'
      ),
      highlights: [
        {
          title: t('products:easy-routines', 'Easy Routines'),
          description: t(
            'products:the-best-routine-is-one-you-use-consistently',
            'The best routine is one you’ll use consistently, so we keep it simple.'
          ),
        },
        {
          title: t('products:quick-plus-simple', 'Quick + Simple'),
          description: t(
            'products:our-products-work-together-to-bring-the-best',
            'Our products work together to bring out the best in your skin in just minutes a day.'
          ),
        },
        {
          title: t('products:remarkable-results', 'Remarkable Results'),
          description: t(
            'products:you-will-feel-results-from-day-one',
            'You’ll feel results from day one and see improvement over time with daily use.'
          ),
        },
      ],
      navFixedPositionOrigin: 665,
      filterNavFixedPositionOrigin: 463,
    },
    {
      value: 'accessories',
      title: t('common:accessories', 'Accessories'),
      titleTag: t('common:accessories', 'Accessories'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/ACCESSORIES_1264x352_f86b2aee-8f6d-46e1-8128-6d7928d2299d.jpg?v=1637234709',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/ACCESSORIES_375x249_c55138a2-9c00-43c4-ac53-675f07f8baed.jpg?v=1637234709',
      header: t('common:accessories', 'Accessories'),
      subHeader: t(
        'products:find-the-perfect-complement-to-your-routine',
        'Find the perfect complement to your routine with our men’s skincare accessories. Boost your results, enhance exfoliation, and get a deeper clean with these essential tools.'
      ),
      description: t(
        'products:our-premium-mens-skincare-boost-your-result',
        'Our premium men’s skincare accessories boost your results, enhance exfoliation, and get you that deep-down clean your skin needs.'
      ),
      filterNavFixedPositionOrigin: 446,
    },
    {
      value: 'clearance-sale',
      title: t('common:clearance-sale', 'Clearance Sale ⚡'),
      titleTag: t('common:clearance-sale', 'Clearance Sale ⚡'),
      heroImage:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/clearance-sales.png?v=1654772882',
      heroImageMobile:
        'https://cdn.shopify.com/s/files/1/2960/5204/files/clearance-sales.png?v=1654772882',
      header: t('common:clearance-sale', 'Clearance Sale ⚡'),
      subHeader: t(
        'products:products-at-a-massive-discount-limited-percent',
        'For a limited time, get our featured products at a massive discount (up to {{percentOff}}% off)!',
        { percentOff: 30 }
      ),
      description: '',
      filterNavFixedPositionOrigin: 446,
    },
  ]
}
