import { TFunction } from 'i18next'

import howToRoutes from '../../../howTo/routes'
import ingredientsRoutes from '../../../ingredients/routes'
import { SLUGS_TO_SHOPIFY_SLUGS } from '../../../products/constants/shopifyProductSlugs'
import productRoutes from '../../../products/routes'
import staticRoutes from '../../../static/routes'

import { getproductConcerns } from 'modules/products/constants/productConcerns'
import { getProductsPageDetails } from 'modules/products/constants/productsPageDetails'
import { returnPolicyPage } from 'modules/shared/constants/general'

export interface NavMenuItem {
  title: string
  href?: string
  subMenus?: Array<NavMenuProduct>
}

export interface NavMenuProduct {
  title: string
  href?: string
  slug?: string
  shopifySlug?: string
  url?: string
  isInternal?: boolean
  isHidden?: boolean
}

const getSlugs = (slug: string): { slug: string; shopifySlug?: string } => {
  const shopifySlug = SLUGS_TO_SHOPIFY_SLUGS[slug]

  return {
    slug,
    shopifySlug,
  }
}

export const getShopMenuItems = (t: TFunction): Array<NavMenuItem> => [
  {
    title: t('common:by-category', 'By Category'),
    subMenus: [
      ...getProductsPageDetails(t).map((category) => ({
        title: category.title,
        href: productRoutes.productsWithQuery({ category: category.value }),
      })),
    ],
  },
  {
    title: t('common:by-concern', 'By Concern'),
    subMenus: [
      ...getproductConcerns(t).map((concern) => ({
        title: concern.title,
        href: productRoutes.productsWithQuery({ concerns: concern.value }),
      })),
    ],
  },
]

export interface LearnMenu {
  title: string
  href: string
  internal?: boolean
}

export const getAboutMenuItems = (t: TFunction) => [
  {
    title: t('common:how-to', 'How To'),
    href: howToRoutes.howTo(),
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/HowTo-min.jpg?v=1641405371',
  },
  {
    title: t('common:Ingredients', 'Ingredients'),
    href: ingredientsRoutes.ingredients(),
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/November02-min.jpg?v=1640943737',
  },
]

export const getSupportMenuItems = (t: TFunction) => [
  {
    title: t('leftNav:faq', 'FAQ'),
    href: 'https://luminskin.zendesk.com/hc/en-us',
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/FAQ-min.jpg?v=1641405371',
  },
  {
    title: t('leftNav:heroes-program', 'Heroes Program'),
    href: staticRoutes.heroesProgramDiscount(),
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/lumin_M_heroprogram_hero-min.jpg?v=1640943737',
  },
  {
    title: t('leftNav:lumin-influencer', 'Lumin Influencer'),
    href: staticRoutes.affiliateInfluencer(),
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/Lumin_September10-min.jpg?v=1640943737',
  },
  {
    title: t('leftNav:return-refund-policy', 'Return & Refund Policy'),
    href: returnPolicyPage,
    img: 'https://cdn.shopify.com/s/files/1/2960/5204/files/December07-min.jpg?v=1640943737',
  },
]
