import { Product } from '@pangaea-holdings/pangaea-checkout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getProductDetails } from '../constants/productDetails'
import { ProductDetails } from '../types'

export function useProductDetails(product: Product) {
  const { t } = useTranslation()
  const [details, setDetails] = useState<ProductDetails | null>(null)

  useEffect(() => {
    ;(async () => {
      const productDetail = await getProductDetails(t, product.id)
      if (productDetail) {
        setDetails(productDetail)
      }
    })()
  }, [product, t])

  return details
}
