import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Box,
} from '@chakra-ui/core'
import React, { FC } from 'react'

import { NavTab } from '../../types'
import AboutNavContent from './AboutNavContent'
import ShopNavContent from './ShopNavContent'
import SupportNavContent from './SupportNavContent'

interface TopHoverNavrProps {
  isOpen: boolean
  onClose: () => void
  selectedNavTab: NavTab | null
}

const TopHoverNav: FC<TopHoverNavrProps> = ({
  isOpen,
  onClose,
  selectedNavTab,
}) => {

  const onLeave = () => {
    onClose()
  }

  if (!selectedNavTab) {
    return null
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="top">
      <DrawerOverlay zIndex="dropdown" position="absolute">
        <DrawerContent
          bgColor="white"
          zIndex="dropdown"
          width="100%"
          onMouseLeave={onLeave}
        >
          <DrawerBody py={7}>
            <Box w="100%" maxW="1440px" m="auto">
              {
                {
                  [NavTab.SHOP]: <ShopNavContent onClose={onClose} />,
                  [NavTab.ABOUT]: <AboutNavContent />,
                  [NavTab.SUPPORT]: <SupportNavContent />,
                }[selectedNavTab]
              }
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default TopHoverNav
