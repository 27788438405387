const referralStorageKey = 'referral_code'

export const saveReferralCodeToStorage = (code: string) =>
  localStorage.setItem(referralStorageKey, code)

export const getReferralCodeFromStorage = () =>
  localStorage.getItem(referralStorageKey)

export const removeReferralCodeFromStorage = () =>
  localStorage.removeItem(referralStorageKey)
