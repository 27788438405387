import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const MagnifyingGlassIcon = createIcon({
  displayName: 'MagnifyingGlassIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
        stroke="#FCFCF9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4434 16.4438L20.9997 21.0002"
        stroke="#FCFCF9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})
