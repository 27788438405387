import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Heading,
  Flex,
  HStack,
  Button,
} from '@chakra-ui/core'
import {
  CartResponse,
  CheckoutContext,
  convertOtpItemsToSubs,
} from '@pangaea-holdings/pangaea-checkout'
import * as Sentry from '@sentry/browser'
import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import productImage from '../../../../public/img/lumin-products.png'
import { getNonClearanceCartItems } from '../functions'

import {
  trackPopupModalClicked,
  trackPopupModalShown,
} from 'modules/lumin/events'
import { useFeatureFlag } from 'modules/optimizely/hooks/useFeatureFlag'
import { defaultSubscriptionInterval } from 'modules/products/constants/productDetails'
import { ProductRenewalDiscountRule } from 'modules/products/types'
import { DiscountBadgeIcon } from 'modules/shared/components/Icons/DiscountBadgeIcon'
import { PlayIcon } from 'modules/shared/components/Icons/Play'
import { RepeatIcon2 } from 'modules/shared/components/Icons/RepeatIcon2'

interface Props {
  cart: CartResponse
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  proceedToCheckout: () => Promise<void>
}

const getSubDiscountPercentFromKey = (discountKey: string): number => {
  const discount = Number(discountKey.split('-')[1])
  return isNaN(discount) || typeof discount !== 'number' ? 20 : discount / 100
}

function MakeItASubscriptionModal({
  cart,
  isOpen,
  setIsOpen,
  proceedToCheckout,
}: Props) {
  const { t } = useTranslation()

  const { dispatch } = useContext(CheckoutContext)

  const [isLoading, setIsLoading] = useState(false)

  const renewalDiscountRule: ProductRenewalDiscountRule = useFeatureFlag(
    'RENEWAL_DISCOUNT_RULE'
  )

  useEffect(() => {
    if (isOpen) {
      trackPopupModalShown('make_it_a_sub')
    }
  }, [isOpen])

  const discountKey = (cart as any).subscriptionDiscountKey

  const renewalPercentOff = discountKey
    ? getSubDiscountPercentFromKey(discountKey)
    : renewalDiscountRule?.percentageOff || 20

  const handleCloseModal = () => {
    trackPopupModalClicked('make_it_a_sub', 'no thanks')
    setIsOpen(false)
  }

  const handleSwitchToSub = async () => {
    try {
      const nonClearanceItems = getNonClearanceCartItems(cart)
      setIsLoading(true)
      await dispatch(
        convertOtpItemsToSubs(nonClearanceItems, defaultSubscriptionInterval)
      )
      await proceedToCheckout()
    } catch (error) {
      Sentry.captureException(`Error while convert cart items to subs ${error}`)
      setIsLoading(false)
      throw error
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay zIndex="modal">
        <ModalContent maxWidth={['380px', '435px']} overflowY="scroll">
          <ModalCloseButton m={[2, 4]} onClick={handleCloseModal} />
          <ModalBody minW="300px" py={4} px={4} maxHeight="100%">
            <Stack color="nightGreen" spacing={[4, 6]} pt={[8, 12]}>
              <Heading textAlign="center" fontSize={['1.7rem', '2rem']}>
                {t(
                  'MakeItASubscription:make-it-a-subscription-&-save',
                  'Make it a subscription & save!'
                )}
              </Heading>
              <Text
                textAlign="center"
                fontSize={['sm', 'md']}
                fontWeight="bold"
              >
                {t(
                  'MakeItASubscription:get-an-additional-percent-off-your-order',
                  'Get an additional {{renewalPercentOff}}% off your order, plus enjoy these additional perks!',
                  { renewalPercentOff }
                )}
              </Text>
              <Flex w="100%">
                <Image
                  width={400}
                  height={238}
                  objectFit="cover"
                  src={productImage}
                  placeholder="blur"
                />
              </Flex>

              <Stack spacing={[2, 6]}>
                <HStack>
                  <DiscountBadgeIcon fill="white" />
                  <Text fontSize={['sm', 'md']} fontWeight="bold">
                    {t(
                      'MakeItASubscription:access-to-exclusive-deals-and-discounts',
                      'Access to exclusive deals and discounts'
                    )}
                  </Text>
                </HStack>
                <HStack>
                  <RepeatIcon2 fill="black" />
                  <Text fontSize={['sm', 'md']} fontWeight="bold">
                    {t(
                      'MakeItASubscription:automatic-percent-off-subscription-renewals',
                      'Automatic {{renewalPercentOff}}% off subscription renewals',
                      { renewalPercentOff }
                    )}
                  </Text>
                </HStack>
                <HStack>
                  <PlayIcon color="white" stroke="black" strokeWidth="30px" />
                  <Text fontSize={['sm', 'md']} fontWeight="bold">
                    {t(
                      'MakeItASubscription:skip-or-cancel-a-shipment-at-any-time',
                      'Skip or cancel a shipment at any time'
                    )}
                  </Text>
                </HStack>
              </Stack>
              <Button
                onClick={handleSwitchToSub}
                py={[2, 4]}
                letterSpacing="0.5px"
                textTransform="uppercase"
                isLoading={isLoading}
              >
                {t(
                  'MakeItASubscription:switch-to-subcription',
                  'Switch to Subcription'
                )}
              </Button>
              <Text
                fontWeight="bold"
                textDecor="underline"
                as="button"
                textTransform="uppercase"
                onClick={() => {
                  handleCloseModal()
                  proceedToCheckout()
                }}
              >
                {t('common:no-thanks', 'No Thanks')}
              </Text>
            </Stack>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default MakeItASubscriptionModal
