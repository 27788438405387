import buildRoutes from '../shared/buildRoutes'

const ingredientsRoutes = buildRoutes('pages/ingredients', {
  ingredients: ({ filter, by }: { filter?: string; by?: string } = {}) => {
    if (!filter && !by) {
      return ''
    }
    const query = {
      filter,
      by,
    }
    const queryParams = Object.keys(query).reduce((queryString, key) => {
      const value = query[key]
      if (value) {
        return `${queryString}${queryString !== '' ? '&' : ''}${key}=${value}`
      }
      return queryString
    }, '')
    return `?${queryParams}`
  },
})

export default ingredientsRoutes
