import { Box, Text, BoxProps } from '@chakra-ui/core'
import React from 'react'

import ShopifyNextLink from 'modules/shared/components/ShopifyNextLink'

type NavCardProps = BoxProps & {
  title: string
  img?: string
  href: string
}

const NavCard: React.FC<NavCardProps> = ({
  img = '',
  title = '',
  href,
  ...props
}) => {
  return (
    <Box {...props}>
      <ShopifyNextLink href={href} as={href}>
        <Box
          as="img"
          src={img}
          width="278px"
          height="278px"
          bgColor="seastone"
        />
        <Text textDecoration="underline" fontSize="0.875rem" mt={4}>
          {title}
        </Text>
      </ShopifyNextLink>
    </Box>
  )
}

export default NavCard
