import { Box } from '@chakra-ui/core'
import { jsx, keyframes, css } from '@emotion/core'

const animateCheck = keyframes`
  from {
    transform: rotate(45deg) scale(0.5, 0.5) translate(-8px, -8px);
    opacity: 0;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
`

export const SuccessMark = () => {
  return (
    <Box
      css={css`
        & > .success-mark {
          width: 100px;
          height: 100px;
          border: 5px solid rgba(110, 123, 112, 0.37);
          border-radius: 100%;
          margin: 30px auto;
          position: relative;
          animation: ${fadeIn} 600ms ease-in-out;
        }

        & > .success-mark::before {
          content: '';
          border: solid #6e7b70;
          border-width: 0px 0px 5px 5px;
          display: inline-block;
          width: 50px;
          height: 23px;
          position: relative;
          top: 25px;
          opacity: 1;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-animation: animate-check 600ms cubic-bezier(1, -0.87, 0, 1.61);
          animation: ${animateCheck} 600ms cubic-bezier(1, -0.87, 0, 1.61);
        }
      `}
    >
      <div className="success-mark"></div>
    </Box>
  )
}
