import { createIcon } from '@chakra-ui/icon'
import React from 'react'

export const InstagramIcon = createIcon({
  displayName: 'InstagramIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M16 2.884c4.272 0 4.779 0.016 6.467 0.093 4.336 0.197 6.361 2.255 6.559 6.559 0.077 1.687 0.092 2.193 0.092 6.465 0 4.273-0.016 4.779-0.092 6.465-0.199 4.3-2.219 6.361-6.559 6.559-1.688 0.077-2.192 0.093-6.467 0.093-4.272 0-4.779-0.016-6.465-0.093-4.347-0.199-6.361-2.265-6.559-6.56-0.077-1.687-0.093-2.192-0.093-6.465 0-4.272 0.017-4.777 0.093-6.465 0.199-4.303 2.219-6.361 6.559-6.559 1.688-0.076 2.193-0.092 6.465-0.092zM16 0c-4.345 0-4.889 0.019-6.596 0.096-5.811 0.267-9.040 3.491-9.307 9.307-0.079 1.708-0.097 2.252-0.097 6.597s0.019 4.891 0.096 6.597c0.267 5.811 3.491 9.040 9.307 9.307 1.708 0.077 2.252 0.096 6.597 0.096s4.891-0.019 6.597-0.096c5.805-0.267 9.043-3.491 9.305-9.307 0.079-1.707 0.097-2.252 0.097-6.597s-0.019-4.889-0.096-6.596c-0.261-5.805-3.489-9.040-9.305-9.307-1.708-0.079-2.253-0.097-6.599-0.097zM16 7.784c-4.537 0-8.216 3.679-8.216 8.216s3.679 8.217 8.216 8.217 8.216-3.679 8.216-8.217c0-4.537-3.679-8.216-8.216-8.216zM16 21.333c-2.945 0-5.333-2.387-5.333-5.333 0-2.945 2.388-5.333 5.333-5.333s5.333 2.388 5.333 5.333c0 2.947-2.388 5.333-5.333 5.333zM24.541 5.54c-1.061 0-1.921 0.86-1.921 1.92s0.86 1.92 1.921 1.92c1.060 0 1.919-0.86 1.919-1.92s-0.859-1.92-1.919-1.92z"
    />
  ),
})
