import { Box, Flex, Text } from '@chakra-ui/core'
import React from 'react'

import ShopifyNextLink from '../../../shared/components/ShopifyNextLink'
import { NavLink } from './footerConstants'

interface ListOfLinksProps {
  listTitle: string
  links: NavLink[]
}

const ListOfLinks: React.FC<ListOfLinksProps> = ({ listTitle, links }) => {
  const fontSize = ['sm', 'md', 'md']
  return (
    <Flex direction="column">
      <Text fontSize={fontSize} fontWeight="bold" mb={3} mt={3}>
        {listTitle}
      </Text>
      {links
        .filter((link) => !link.isHidden)
        .map((link, index) => {
          return (
            <Box
              key={index}
              mb={3}
              fontSize={fontSize}
              className={link.className}
            >
              <ShopifyNextLink
                href={link.href}
                isInternal={!!link.internal}
                _hover={{
                  textDecoration: 'underline',
                }}
              >
                {link.title}
              </ShopifyNextLink>
            </Box>
          )
        })}
    </Flex>
  )
}

export default ListOfLinks
