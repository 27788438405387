//Customers should not be able to apply these code at checkout

import { SpecialCoupons } from '@pangaea-holdings/pangaea-checkout';

export const COUPON_KEYS = {
  QUERY: 'override_coupon',
  COOKIE: 'override_coupons',
}
//And these codes shouldn't be visible to the customer when automatically applied
const SPECIAL_DISCOUNT_CODES: RegExp[] = [
  /^CANCEL/i,
  /^FREE_TRIAL/i,
  /HOLIDAY2020$/i,
  /^JANFLASH.*/i,
  /DISCOUNT_2021_50/,
  /DISCOUNT_2021_30/,
  /DISCOUNT_2021_20/,
  /HANDSTOGETHER/,
  /REFERRALCAMPAIGN/i,
  /BUNDLE_DISCOUNT_/i,
]

export const isValidDiscountCode = (code: string): boolean => {
  const isInvalid = SPECIAL_DISCOUNT_CODES.some((re) => re.test(code))
  return !isInvalid
}

export const BUNDLE_DISCOUNTS = [
  'BUNDLE_DISCOUNT_10',
  'BUNDLE_DISCOUNT_15',
  'BUNDLE_DISCOUNT_20',
  'FREE_TRIAL_BUNDLE_DISCOUNT_10',
  'FREE_TRIAL_BUNDLE_DISCOUNT_15',
  'FREE_TRIAL_BUNDLE_DISCOUNT_20',
]

export const specialCoupons: SpecialCoupons = {
  invisibleCoupons: [
    'DISCOUNT_2021_20',
    'DISCOUNT_2021_30',
    'DISCOUNT_2021_50',
    'REFERRALCAMPAIGN',
    ...BUNDLE_DISCOUNTS,
  ],
  nonRemovableCoupons: [],
  stackableCoupons: [...BUNDLE_DISCOUNTS],
}